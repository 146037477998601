import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const CollegeWritingServicePage = () => (
  <Layout
    pageWrapperClassName="buy-college-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Buy College Essays - Buy College Papers Online"
      meta={[
        {
          name: 'description',
          content: 'Buy College Papers Online. Its team of professional writers ensures that clients get top-notch quality essays within tight deadlines. Clients’ reviews prove that this is the best place to Buy College Essays.',
        },

        {
          property: 'og:title',
          content: 'Buy College Essays Online with Top Quality | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Buy college essays online at affordable prices. Top-quality essays from specialists with all the needed experience and qualifications. Compliance with your requirements, affordability, attention to detail, 24/7 service. Buy now and get your paper fast.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/buy-college.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Ask for assistance with
        <br className="first-screen-section__title__br" />
        {' '}
        your college assignments
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Hire expert writers to receive top-notch papers
        <br className="first-screen-section__heading__br" />
        {' '}
        for your college
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Buy college papers online: buy college essays
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            It’s not a secret that the life of a college or university student can be difficult at
            times. One of the reasons is the enormous number of papers and essays a student must
            write. This is particularly true at the end of a term. Students must submit at least one
            research paper for each course.
          </p>
          <p>
            Now, the problem is that many students come to university without knowing how to write
            an academic paper. If they cannot write one essay, for example, how will they write so
            many assignments within a short time? One possible solution is to buy college essays and
            improve your writing skills using papers composed by professionals.
          </p>
          <p>
            But, where can one buy an academic paper? The answer is simple, on the Internet. Some
            online companies like TakeAwayEssay offer papers for sale.{' '}
            <strong>
              To purchase one, you have to place an order in which you specify the characteristics
              of the paper you need. A professional writer will then type it according to your
              requirements.
            </strong>
          </p>
          <p>
            It seems that the life of a college student can be more relaxed with the help from such
            services, right? You just buy college essays online and the tons of homework on your
            desk become easier to complete. This is true. But you must find a company that has the
            best college essays for sale.
          </p>
          <h2 className="h3">
            Buy college papers online from a company that offers premium writing
          </h2>
          <p>
            You need to collaborate with a writing company that can help you with your assignments
            since your admission to college. This is{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>, the company with
            the best college essays for sale. The company relies on a team of highly skilled
            writers. Most of them are native English speakers and have expertise in many academic
            subjects.
          </p>
          <p>
            What are the characteristics that make these papers so good? Well, these are some of
            them:
          </p>
          <ul className="dot-list">
            <li>
              They are written in flawless technical English. The tone is appropriate according to
              the information you provide in the order.
            </li>
            <li>
              They are technically sound. The person writing your paper is also a specialist in your
              subject. The depth of the text depends on the academic level (bachelor’s degree,
              master’s degree, PhD).
            </li>
            <li>
              They are unique and original. Owing to a strict non plagiarism policy, all the writers
              know they should never reuse previously published materials.
            </li>
          </ul>
          <p>
            Another thing that makes our company stand out is our customer service. Our prices are
            quite affordable, even cheap. For what you pay, you can be sure you get the best value
            for your money. If you buy college essays online, you must buy them from a reliable
            company like ours.
          </p>
          <h2 className="h3">Order the best college essays for sale online</h2>
          <p>
            Before you buy college papers, you must be sure that they can be delivered on time. You
            want the writing and editing processes to be fast, especially for urgent assignments.
            Our company can do it. Our professional writers work around the clock to meet even the
            strictest deadlines.
          </p>
          <p>
            The writers never compromise the quality of any paper. You can be sure you will always
            get a high-quality custom paper. You can entrust us with any kind of assignment, from
            your application to your final dissertation. We will always deliver a quick and cheap
            solution. Our website is, without any doubt, the best alternative to buy college papers
            online.
          </p>
          <h2 className="h3">High-quality college papers for sale are the best solution</h2>
          <p>
            When you want to ask someone “help me with my homework,” you know you must contact{' '}
            <a href="https://takeawayessay.com/">https://takeawayessay.com/</a>. We can help you to
            have a much more enjoyable student life by improving your knowledge of your discipline.
            True, our services are not free. But these paid services are a good investment for your
            future.
          </p>
          <p>
            Buying college papers for sale will allow you to improve your writing skills and get top
            grades in all the courses as a result. Remember that students with top grades will
            eventually get the best jobs. Hence, using these writing services is a wise decision.
          </p>
          <p>
            You shouldn’t worry about anyone finding out about your using these services. Our
            company treats all the orders as a confidential and private matter. It’s a deal between
            you and us only. Thanks to our privacy policy, your personal details are protected.
          </p>
          <p>
            Hence, take advantage of the college papers for sale. Get help from our company to have
            a more relaxed student life. With better time-management and writing skills, you will
            have more time for friends, family, and hobbies. You shouldn’t spend many sleepless
            nights trying to complete all your homework on your own. Look for help when you need it.
            Place your order right now!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Get help with
      <br />
      {' '}
      your college papers now.
    </CTA>

  </Layout>
);

export default CollegeWritingServicePage;
